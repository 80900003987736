<template>
    <main class="category-main">
        <div class="category-main_header">
            <h4 class="category-main_title">Навчальних курсів: <span>{{ totalCount }}</span></h4>
            <p class="category-main_description">Оберіть сподобавшийся курс з нашої бази</p>
        </div>
        <div class="courses">
            <course-card v-for="(course, index) in courses"
                         :icon="course.icon"
                         :title="course.title"
                         :url="course.url"
                         :key="index"
                         :date="course.date"
                         :category="course.category"
                         />
            <observer @intersect="infiniteHandler"/>
        </div>
    </main>
</template>

<script>
    import StudyService from "../../services/StudyService";
    import CourseCard from "./CourseCard";
    import Observer from "../app/Observer";

    export default {
        name: "CourseCards",
        components: {
            CourseCard,
            Observer
        },
        data() {
            return {
                courses: [],
                totalCount: 0,
                scrollActive: true,
                offset: 0,
                limit: 40
            }
        },
        methods: {
            async infiniteHandler()
            {
                if(!this.scrollActive) {
                    return;
                }

                let data = await StudyService.getCourses(this.limit, this.offset, this.$route.params.id);

                if (data.items.length) {
                    let courses = this.parseCourses(data.items);

                    this.offset += this.limit;
                    this.totalCount = data.totalCount;
                    this.courses = [...this.courses, ...courses];
                    this.scrollActive = data.length === this.limit;
                } else {
                    this.scrollActive = false
                }
            },
            parseCourses(items)
            {
                return items.map(item => {
                    const date = item.end_date ? this.$moment(item.end_date).format("DD/MM/YYYY") : null;
                    return {
                        url: '/study/' + this.$route.params.id + '/course/' + item.id,
                        title: item.title,
                        icon: item.photo,
                        category: item.category_title,
                        date: date,
                    }
                });
            }
        }
    }
</script>

<style scoped lang="less">

</style>
