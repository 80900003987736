<template>
    <section class="category">
        <div class="category-wrapper">
            <CommonBlock
                :title="category.title"
                :visible-back-btn="true"
                :breadcrumbs="breadcrumbs"
                :cancelBackBtnEventPassed="true"
                @clickBack="clickBack"
            >
                <p class="category-text">{{ this.category.description }}</p>
            </CommonBlock>

            <course-cards />
        </div>
    </section>
</template>

<script>
    import StudyService from "../services/StudyService";
    import CourseCards from "../components/study/CourseCards";
    import CommonBlock from "../components/CommonBlock";

    export default {
        components: {CourseCards, CommonBlock},
        props: {
            id: {
                type: Number,
                required: false
            },
        },
        data() {
            return {
                category: { },
                 breadcrumbs: [
                    {id: 1, name: 'Навчання', link: '/study' },
                ],
                view: {},
            };
        },
        async beforeMount() {
            this.category = await StudyService.getCategory(this.$route.params.id);
            document.title = this.category.title;
            this.breadcrumbs.push({id: 2, name: this.category.title })
        },
        methods: {
            clickBack() {
                if(Object.keys(this.view).length) {
                    this.view = {};
                }else{
                    this.$router.go(-1);
                }
            },
        }
    };
</script>

<style lang="less">
    .category {
        background: #F2F2F2;
        .common-block {
            background: #1a2744;
        }
        &-wrapper {
            display: flex;
        }
        &-inner {
            height: 100vh;
            width: 30%;
            background: #1A2744;
            padding: 80px 37px 80px 80px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow-y: auto;
        }
        &-title {
            font-weight: bold;
            font-size: 56px;
            line-height: 64px;
            letter-spacing: -0.392px;
            color: #fff;
            margin-bottom: 40px;
        }
        &-text {
            font-weight: normal;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.14px;
            color: #FFFFFF;
            margin-bottom: 35px;
        }
        &-main {
            padding: 80px 40px 20px 40px;
            width: 70%;
            overflow-y: auto;
            height: 100vh;
            &_header {
                position: relative;
                padding-bottom: 20px;
                margin-bottom: 40px;
                &::before {
                    position: absolute;
                    content: '';
                    bottom: 0;
                    width: 100%;
                    height: 1px;
                    background:#000000;
                    opacity: 0.15;
                }
            }
            &_title {
                font-weight: bold;
                font-size: 32px;
                line-height: 40px;
                color: #1A2744;
                margin-bottom: 10px;
                span {
                    margin-right: 5px;
                }
            }
            &_description {
                font-weight: normal;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: -0.14px;
                color: #1A2744;
            }
        }

    }

    .courses {
        /* display: flex;
        justify-content: space-between;
        flex-wrap: wrap; */
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }
    .courses-item {
        /*width: calc(50% - 5px); */
        width: calc(100% / 12 * 6 );
        height: 256px;

        cursor: pointer;
        overflow: hidden;

        background: #fff;
        display: flex;
        position: relative;
        margin-bottom: 10px;
        text-decoration: none;

        &_img {
            width: 200px;
            position: relative;
            overflow: hidden;
            &::before {
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: #1A2744;
                opacity: 0.6;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &_info {
            padding: 40px 15px 26px 40px;
            width: 395px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        &_icon {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            background: #FFC900;
            border-radius: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
        }
        &_category {
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #9DA3AE;
            margin-bottom: 10px;
        }
        &_title {
            font-weight: bold;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.14px;
            color: #1A2744;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        .date {
            position: absolute;
            top: 22px;
            right: 25px;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.084px;
            text-transform: uppercase;
            color: #9DA3AE;
        }
        &:hover {
            // transform: scale(1.01);
            box-shadow: 0px 24px 24px -14px rgb(249 198 35 / 35%);
        }
    }


    .header {
        width: 100%;
    }
    .header-logo {
        //   width: 253px;
        height: 51px;
        margin-bottom: 22px;
        display: inline-block;
        img {
            width: 100%;
            height: 100%;
        }
    }


    /* breadcrumb */
    .breadcrumb {
        list-style: none;
        margin-bottom: 45px;
    }
    .breadcrumb-item {
        display: inline;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: -0.112px;
        color: #fff;
        margin-right: 20px;
    }

    .breadcrumb-link {
        font-size: 16px;
        line-height: 30px;
        letter-spacing: -0.112px;
        color: #ffc900;
        text-decoration: none;
        position: relative;
        &::after {
            position: absolute;
            content: '';
            width: 1px;
            height: 15px;
            background: #fff;
            bottom: 1px;
            right: -11px;
        }
    }

    .btn-back {
        display: flex;
        align-items: center;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #ffc900;
        text-decoration: none;
        &_icon {
            width: 56px;
            height: 56px;
            border-radius: 50%;
            background: #ffc900;
            box-shadow: 0px 24px 24px -14px rgba(249, 198, 35, 0.35);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 20px;
        }
    }


    @media screen and (max-width: 1920px) {
        .category {
            &-inner {
                height: 100vh;
                max-width: 640px;
                width: 30%;
            }
            &-main {
                width: 70%;
                max-width: 70%;
                height: 100vh;
            }
        }
        .courses-item {
            /*max-width: 595px;
            max-width: 49%;*/
             width: calc(100% / 12 * 6 - 5px );
            height: 256px;

            &:nth-child(2n) {
                margin-left: auto;
            }
        }
    }

    @media screen and (max-width: 1600px) {
        .category {
            &-inner {
                padding: 40px 40px 40px 60px;
            }
            &-title {
                font-size: 32px;
                line-height: 40px;
                margin-bottom: 20px;
            }
            &-text {
                font-size: 18px;
                line-height: 26px;
                margin-bottom: 20px;
            }
        }
    }

    @media screen and (max-width: 1600px) {
        .category {
            &-inner {
                padding: 40px 40px 40px 60px;
            }
            &-title {
                font-size: 36px;
                line-height: 42px;
                margin-bottom: 20px;
            }
            &-text {
                font-size: 18px;
                line-height: 26px;
                margin-bottom: 20px;
            }
            &-main {
                padding-top: 40px;
            }
        }

    }
    @media screen and (max-width: 1440px) {
        .category {
            &-title {
                font-size: 30px;
                line-height: 40px;
                margin-bottom: 20px;
            }
            &-text {
                font-size: 16px;
                line-height: 24px;
            }
        }
        .courses-item {
            height: 200px;
        }
        .courses-item_info {
            padding: 20px;
            width: 60%;
        }
        .courses-item_img {
            width: 40%;
        }
        .courses-item_icon {
            width: 50px;
            height: 50px;
        }
        .courses-item_category {
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 16px;
        }
        .courses-item_title {
            font-size: 14px;
            line-height: 16px;
        }
        .category-inner {
            padding: 40px 40px 40px 50px;
        }
        .category-main {
            padding: 40px 30px;
        }
    }
    @media screen and (max-width: 1200px) {
        .courses-item {
            height: 220px;
        }
        .courses-item_category {
            font-size: 12px;
        }
    }
    @media screen and (max-width: 1100px) {
        .courses-item {
            max-width: 100%;
            width: 100%;
        }
    }
    @media screen and (max-width: 992px) {
       .category {
            &-wrapper {
                display: block;
            }
            &-title {
                font-size: 20px;
                line-height: 30px;
            }
            &-text {
                display: none;
            }
            &-main {
                max-width: 100%;
                width: 100%;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .category-main_header {
            display: none;
        }
        /*.courses-item_img {
            display: none;
        } */
        .courses-item_info {
            width: 100%;
        }
        .category-main {
            padding: 20px;
        }
    }
    @media screen and (max-width: 600px) {
         .courses-item_img {
            display: none;
        }
    }
    .common-block{
        @media (max-height: 992px){
            min-height: auto!important;
        }
    }
    ::v-deep{
        .common-block{
            @media (max-height: 992px){
                min-height: auto!important;
            }
        }
    }
</style>
